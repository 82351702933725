import {Injectable, Renderer2, RendererFactory2} from "@angular/core";
import {Observable} from "rxjs";
import {InjectionStatus} from "@core/models/injection-status.enum";
import {LoggerService} from "@services/logger-service.service";
import {RequestErrorInfo} from "@core/models/errors/request-error-info.model";

@Injectable()
export class ScriptInjectorService {
  private renderer: Renderer2;

  constructor(private logger: LoggerService,
              private rendererFactory: RendererFactory2) {
    this.logger.debug(this.constructor.name + " is created.");
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  public inject(src: string, globalVarName: string, globalVarNameMethod: string = undefined): Observable<InjectionStatus> {
    return new Observable(observer => {
      const head = document.querySelector("head");
      const scripts = Array.from(head.querySelectorAll("script"));
      const isScriptToInjectAlreadyAppended = scripts.some(script => script.src === src);

      if (!isScriptToInjectAlreadyAppended) {
        const script = this.renderer.createElement("script");
        script.src = src;
        script.async = true;
        script.defer = true;
        this.renderer.appendChild(head, script);

        const INTERVAL = 100;
        let timeoutTimer = 0;

        const onloadTimer = setInterval(() => {
          let loaded = false;
          timeoutTimer += INTERVAL;

          if (globalVarNameMethod) {
            if (typeof window[globalVarName] !== "undefined"
              && typeof window[globalVarName][globalVarNameMethod] !== "undefined") {
              loaded = true;
            }
          } else {
            if (globalVarName && typeof window[globalVarName] !== "undefined") {
              loaded = true;
            }
          }

          if (loaded) {
            clearInterval(onloadTimer);
            observer.next(InjectionStatus.LOADED);
            observer.complete();
          }

          if (timeoutTimer >= INTERVAL * 100) {
            const errorInfo = new RequestErrorInfo();
            errorInfo.code = InjectionStatus.REQUEST_TIMED_OUT.toString();
            this.renderer.removeChild(head, script);
            observer.error(errorInfo);
            observer.complete();
          }

        }, INTERVAL);
      } else {
        observer.next(InjectionStatus.ALREADY_ADDED);
        observer.complete();
      }
    });
  }
}
